<template>
  <div>
    <validation-observer #default="{ invalid }">
      <form>
        <b-card>
          <b-row>
            <b-col md="4"><h4>Financial Analysis Messages</h4></b-col>
            <b-col md="4" offset-md="4" align="right">
              <span
                v-if="fullSmsSelected.default_message == 'YES'"
                class="text-info mr-1 text-muted"
                style="font-weight: bold"
              >
                (*) DEFAULT MESSAGE
              </span>

              <b-button
                v-else
                variant="outline-info"
                size="sm"
                class="mr-1"
                :title="
                  options.length == 0
                    ? 'No messages to set as default'
                    : 'Set as default'
                "
                :disabled="options.length == 0"
                @click="setMessageAsDefault(fullSmsSelected.id)"
              >
                <feather-icon icon="StarIcon"> </feather-icon>
                SET AS DEFAULT</b-button
              >

              <b-button
                variant="outline-primary"
                size="sm"
                @click="showAddModal"
              >
                <feather-icon icon="PlusIcon"> </feather-icon>
                ADD</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Select Message">
                <v-select
                  v-model="smsSelected"
                  :options="options"
                  label="message"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <validation-provider
                #default="{ errors }"
                rules="required|containsAtRange:2"
              >
                <b-form-group label="Message in Spanish" label-for="textarea-1">
                  <b-form-textarea
                    v-model="fullSmsSelected.message"
                    :disabled="isDisable"
                    id="textarea-1"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :class="errors[0] ? 'border-danger' : ''"
                    style="height: 160px; max-height: 200px"
                  ></b-form-textarea>
                </b-form-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col>
              <validation-provider
                #default="{ errors }"
                rules="required|containsAtRange:2"
              >
                <b-form-group label="Message in Spanish" label-for="textarea-1">
                  <b-form-textarea
                    v-model="fullSmsSelected.en_message"
                    :disabled="isDisable"
                    id="textarea-1"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :class="errors[0] ? 'border-danger' : ''"
                    style="height: 160px; max-height: 200px"
                  ></b-form-textarea>
                </b-form-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row v-if="fullSmsSelected.id">
            <b-col cols="12">
              <b-row style="margin-bottom: 10px">
                <b-col>
                  <span style="font-weight: bold">VARS</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="2 mb-1">
                  <b-input-group size="lg" prepend="@1">
                    <b-form-input
                      id="input-1"
                      trim
                      disabled
                      placeholder="Link"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col lg="2 mb-1">
                  <b-input-group size="lg" prepend="@2">
                    <b-form-input
                      id="input-1"
                      trim
                      disabled
                      placeholder="Full Name"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="isDisable" lg="12" class="text-center">
              <b-button variant="warning" @click="isDisable = false">
                <feather-icon icon="Edit3Icon"> </feather-icon>
                UPDATE</b-button
              >
            </b-col>

            <b-col v-if="!isDisable" lg="12" class="text-center">
              <b-button
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="createOrUpdateDefaultSmsFinancialAnalysis()"
              >
                <feather-icon icon="SaveIcon"> </feather-icon>
                SAVE</b-button
              >

              <b-button
                class="mr-1"
                @click="createOrUpdateDefaultSmsFinancialAnalysis('remove')"
              >
                <feather-icon icon="TrashIcon"> </feather-icon>
                REMOVE</b-button
              >

              <b-button
                variant="danger"
                @click="(isDisable = true), getOptions()"
              >
                <feather-icon icon="XIcon"> </feather-icon>
                RETURN</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </form>
    </validation-observer>

    <AddDefaultModal v-if="showModal" @hide="hide" />
  </div>
</template>

<script>
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import AddDefaultModal from "@/views/commons/components/settings/views/modal/AddDefaultMessage.vue";
import { mapGetters } from "vuex";

export default {
  name: "FinancialAnalysisSMS",
  components: {
    AddDefaultModal,
  },
  data() {
    return {
      showModal: false,
      options: [],
      smsSelectedOptions: [],
      smsSelected: {},
      fullSmsSelected: {
        message: "",
        en_message: "",
        status: "",
        id: "",
        default_message: "",
      },
      offerSmsEn: null,
      isDisable: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    smsSelected: {
      handler(newVal) {
        this.fullSmsSelected = { ...newVal };
      },
      deep: true,
    },
  },
  async created() {
    await this.getOptions();
  },
  methods: {
    async setMessageAsDefault(id) {
      try {
        let params = {
          message_id: id,
        };
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const { status } =
            await SettingsService.setDefaultFinancialAnalysisMessages(params);
          if (status == 200) {
            this.showSuccessSwal(
              "Successfully!",
              "The message has been set by default"
            );
            this.getOptions();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    showAddModal() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      this.getOptions();
    },
    async getOptions() {
      const { data, status } =
        await SettingsService.getDefaultSmsFinancialAnalysis();
      if (status === 200) {
        this.options = data;
        if (data.length > 0) {
          this.smsSelected = data[0];
        }
      }
    },

    async createOrUpdateDefaultSmsFinancialAnalysis(action = null) {
      try {
        const swalConfirm = await this.showConfirmSwal();
        if (swalConfirm.isConfirmed) {
          const params = {
            id: this.fullSmsSelected.id,
            message: this.fullSmsSelected.message,
            en_message: this.fullSmsSelected.en_message,
            status: action == "remove" ? 0 : 1,
          };
          const response =
            await SettingsService.createOrUpdateDefaultSmsFinancialAnalysis(
              params
            );
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.getOptions();
            this.isDisable = true;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
</style>
