<template>
  <b-modal
    ref="myModal"
    v-model="modalCenter"
    centered
    modal
    size="lg"
    header-class="p-0"
    no-close-on-backdrop
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="modal-primary"
    title="TRACKING"
    title-class="h2 text-white"
    @hidden="hideModal"
  >
    <template v-slot:modal-header>
      <ModalHeader
        :dataItem="dataClient"
        :title="'ASSIGN SELL PERMISSION'"
        @close="hideModal()"
      />
    </template>
    <b-row>
      <b-col>
        <b-form-group label="Level" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-1"
            v-model="selected"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radio-options"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button variant="primary" @click="save()" disabled:flagdisabled>Save</b-button>
    </template>
  </b-modal>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataClient: {
      type: Object,
    },
    flagdisabled:{
        type:Boolean
    }
  },
  data() {
    return {
      modalCenter: false,
      selected: "1",
      options: [
        { text: "Begginer", value: "1" },
        { text: "Junior", value: "2" },
        { text: "Senior", value: "3" },
      ],
    };
  },
  created() {
    this.modalCenter = true;
  },
  methods: {
    save(){
         this.$emit("updatePermission", this.dataClient, 1, this.selected)
        
    },
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal",this.dataClient);
    //   this.$emit("refresh")
    },
  },
};
</script>
