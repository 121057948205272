import { amgApi } from "@/service/axios";

class SettingsService{

    async getAllUsuarios(params){
        const data = await amgApi.post("/crm/settings/get-All-crm-settings-usuarios",params);
        return data;
    }

    async updatePermissionByIdUserModule(params){
        
        const data = await amgApi.post("/crm/settings/update-permission-by-id-user-module",params)
        return data
    }

    async getTrackingSettingById(params){
        const data = await amgApi.post("/crm/settings/get-tracking-setting-by-id",params)
        return data
    }

    async getCountPermissionPending(){
        const data = await amgApi.post("/management/settings/get-count-permission-pending");
        return data;
    }

}

export default new SettingsService()