<template>
  <b-modal
    v-model="show"
    title-class="h3 text-white"
    centered
    size="lg"
    title="Add Message"
    hide-footer
    modal-class="modal-primary"
    @hidden="hideModal()"
  >
    <validation-observer #default="{ invalid }">
      <b-row>
        <b-col>
          <b-form class="auth-login-form" @submit.prevent="addMotive">
            <b-form-group label="Message in Spanish">
              <validation-provider
                #default="{ errors }"
                rules="required|containsAtRange:2"
              >
                <b-form-textarea
                  v-model="form.message"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  style="height: 100px; max-height: 150px"
                  :class="errors[0] ? 'border-danger' : ''"
                ></b-form-textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Message in English">
              <validation-provider
                #default="{ errors }"
                rules="required|containsAtRange:2"
              >
                <b-form-textarea
                  v-model="form.en_message"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  style="height: 100px; max-height: 150px"
                  :class="errors[0] ? 'border-danger' : ''"
                ></b-form-textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              block
              :disabled="invalid"
            >
              <feather-icon icon="SaveIcon"> </feather-icon>
              SAVE</b-button
            >
          </b-form>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <span style="font-weight: bold" class="text-muted"
            >The message must have the following vars.
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col lg="6">
          <b-input-group size="lg" prepend="@1">
            <b-form-input
              id="input-1"
              trim
              disabled
              placeholder="Link"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="6">
          <b-input-group size="lg" prepend="@2">
            <b-form-input
              id="input-1"
              trim
              disabled
              placeholder="Full Name"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import SettingsService from "@/views/commons/components/settings/services/Settings.service";

export default {
  name: "ModalAddMotive",

  data() {
    return {
      show: true,
      form: {
        message: "",
      },
      isDisable: false,
    };
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async addMotive() {
      try {
        this.addPreloader();
        const params = {
          message: this.form.message,
          en_message: this.form.en_message,
        };
        const response =
          await SettingsService.createOrUpdateDefaultSmsFinancialAnalysis(
            params
          );
        if (response.status === 200) {
          this.showSuccessSwal();
          this.$emit("hide", true);
        }
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>