<template>
    <FinancialAnalysisSMS />
</template>

<script>

import FinancialAnalysisSMS from  "@/views/commons/components/settings/views/components/FinancialAnalysisSMS.vue";

export default {
    components: {
        FinancialAnalysisSMS,
    },
    data() {
      return {
        
      }
    },
    created() {
  
    },
    methods: {
      
    },
  }



</script>