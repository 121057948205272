export default [
  {
    key: "id",
    label: "Id",
    visible: false,
  },
  {
    key: "usuario",
    label: "User",
    visible: true,
  },
  {
    key: "status_session",
    label: "Status session",
    visible: true,
  },
  {
    key: "country",
    label: "Country",
    visible: false,
  },
  {
    key: "typesenior",
    label: "Current Level",
    visible: true
  },
  {
    key: "permission",
    label: "Sale Permission",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "change_permission",
    label: "Change Permission",
    visible: false,
    thClass: "text-center",
  },
 
  
  {
    key: "user_module_id",
    label: "User_module_id",
    visible: false,
  },
  {
    key: "status_permission",
    label: "Status Permission",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "tracking",
    visible: true,
    thClass: "text-center",
  },
];
