var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"border-top-primary border-3 border-table-radius px-0"},[_c('div',[_c('filter-slot',{attrs:{"filter-principal":_vm.filterPrincipal,"filter":_vm.filter,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":_vm.refresh},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refSettingsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.getAllUsuarios,"fields":_vm.visibleFields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(permission)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"b-row":""}},[_c('div',{attrs:{"b-col":""}},[_c('b-form-checkbox',{attrs:{"id":("checkbook22-" + (data.index)),"value":"1","unchecked-value":"0","name":("checkbook221-" + (data.index)),"disabled":_vm.disbaledCheckBox(data)},on:{"change":function($event){return _vm.onChangeSegunCheck(data.item)}},model:{value:(data.item.can_sell),callback:function ($$v) {_vm.$set(data.item, "can_sell", $$v)},expression:"data.item.can_sell"}})],1),_c('div',{attrs:{"b-col":""}},[(
                      // data.item.typesenior_pending !== null &&
                      data.item.can_sell !== 0 && data.item.can_sell
                    )?_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.item.type_pending),expression:"data.item.type_pending"}],staticClass:"form-control",staticStyle:{"font-size":"11px","width":"110%"},attrs:{"disabled":_vm.disbaledCheckBox(data)},on:{"input":function (e) {
                        _vm.onChangeSelect(e, data.item);
                      },"click":function (e) {
                        _vm.onChangeSelect(e, data.item);
                      },"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data.item, "type_pending", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.options),function(option){return _c('option',{key:option.value,attrs:{"disabled":_vm.disabledItemSelect(data.item, option.value)},domProps:{"value":option.value,"selected":option.value == data.item.type_pending}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0):_vm._e()])])]}},{key:"cell(status_session)",fn:function(data){return [(data.item.status_session === 1)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#00d25b","color":"#00d25b"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Active ")],1):_vm._e(),(data.item.status_session === 2)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#ff9f43","color":"#ff9f43"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Busy ")],1):_vm._e(),(data.item.status_session === 3)?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#fc424a","color":"#fc424a"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Away ")],1):_vm._e(),(
                  data.item.status_session === 4 ||
                  data.item.status_session === 0
                )?_c('span',[_c('feather-icon',{staticStyle:{"fill":"#82868b","color":"#82868b"},attrs:{"icon":"CircleIcon","size":"17"}}),_vm._v(" Offline ")],1):_vm._e()]}},{key:"cell(typesenior)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.typesenior2 === null ? "-" : data.item.typesenior2)+" ")]}},{key:"cell(status_permission)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-v-center"},[(data.item.status_filter_crm === 'DENIED' || data.item.status_filter_crm === 'APPROVED' || data.item.status_filter_crm === 'PENDING')?_c('b-badge',{staticClass:"text-center w-100",class:data.item.status_filter_crm === 'DENIED'
                      ? 'bg-light-danger'
                      : data.item.status_filter_crm === 'APPROVED'
                      ? 'bg-light-success'
                      : 'bg-light-warning',attrs:{"pill":""}},[_vm._v(" "+_vm._s(data.item.status_filter_crm)+" ("+_vm._s(data.item.typesenior_pending)+") ")]):_vm._e()],1)]}},{key:"cell(tracking)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"outline"},on:{"click":function($event){return _vm.openModalTracking(data.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Tracking'),expression:"'Tracking'",modifiers:{"hover":true,"right":true}}],staticClass:"text-info",attrs:{"icon":"ListIcon"}})],1)],1)]}}])})]},proxy:true}])})],1)]),(_vm.modalTrackingState)?_c('modal-tracking',{attrs:{"dataItem":_vm.dataItem},on:{"hideModal":function($event){_vm.modalTrackingState = false}}}):_vm._e(),(_vm.modalCategoryState)?_c('modal-category',{attrs:{"dataClient":_vm.dataItem,"flagdisabled":_vm.flagdisabled},on:{"hideModal":_vm.closeModal,"updatePermission":_vm.updatePermission,"refresh":_vm.refresh}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }