<template>
  <div>
    <div class="border-top-primary border-3 border-table-radius px-0">
      <div>
        <filter-slot
          :filter-principal="filterPrincipal"
          :filter="filter"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="refresh"
        >
          <template #table>
            <b-table
              slot="table"
              ref="refSettingsList"
              small
              no-provider-filtering
              :items="getAllUsuarios"
              :fields="visibleFields"
              primary-key="id"
              table-class="text-nowrap"
              responsive="sm"
              show-empty
              sticky-header="70vh"
              :busy.sync="isBusy"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-slot:cell(permission)="data">
                <!-- {{data.item.typesenior.toUpperCase()}} -->
                <!-- <b-row>
               {{data.item.user_module_id}}
               {{data.item.can_sell}}
             <b-col> -->
                <div
                  b-row
                  class="d-flex justify-content-center align-items-center"
                >
                  <div b-col>
                    <b-form-checkbox
                      :id="`checkbook22-${data.index}`"
                      v-model="data.item.can_sell"
                      value="1"
                      unchecked-value="0"
                      :name="`checkbook221-${data.index}`"
                      @change="onChangeSegunCheck(data.item)"
                      :disabled="disbaledCheckBox(data)"
                    />
                  </div>
                  <div b-col>
                    <!-- {{data.item.typesenior}} -->
                    <select
                      v-if="
                        // data.item.typesenior_pending !== null &&
                        data.item.can_sell !== 0 && data.item.can_sell
                      "
                      v-model="data.item.type_pending"
                      :disabled="disbaledCheckBox(data)"
                      class="form-control"
                      @input="
                        (e) => {
                          onChangeSelect(e, data.item);
                        }
                      "
                      @click="
                        (e) => {
                          onChangeSelect(e, data.item);
                        }
                      "
                      style="font-size: 11px; width: 110%"
                    >
                      <option
                        v-for="option in options"
                        :key="option.value"
                        :value="option.value"
                        :selected="option.value == data.item.type_pending"
                        :disabled="disabledItemSelect(data.item, option.value)"
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>

                <!-- </b-col>
             </b-row> -->
              </template>
              <!-- <template v-slot:cell(change_permission)="data">
                <div class="d-flex justify-content-center align-v-center">
                  <span v-if="data.item.can_sell===1" role="button" @click="openModalCategory(data.item)"  >
                    <feather-icon
                    icon="EditIcon"
                    size="17"
                    >

                    </feather-icon>
                  </span>
                </div>
              </template> -->
              <template v-slot:cell(status_session)="data">
                <span v-if="data.item.status_session === 1">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #00d25b; color: #00d25b"
                  />
                  Active
                </span>
                <span v-if="data.item.status_session === 2">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #ff9f43; color: #ff9f43"
                  />
                  Busy
                </span>
                <span v-if="data.item.status_session === 3">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #fc424a; color: #fc424a"
                  />
                  Away
                </span>
                <span
                  v-if="
                    data.item.status_session === 4 ||
                    data.item.status_session === 0
                  "
                >
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #82868b; color: #82868b"
                  />
                  Offline
                </span>
              </template>
              <template #cell(typesenior)="data">
                <!-- {{data.item.typesenior}} -->
                <!-- {{
                  (data.item.typesenior === "begginer" &&
                    data.item.can_sell === 0) ||
                  data.item.can_sell === null ||
                  data.item.typesenior === "" ||
                  (data.item.typesenior === "begginer" &&  data.item.can_sell === 1 &&
                  (data.item.flag_settings_management === "PENDING" ||
                  data.item.flag_settings_management === "DENIED") &&
                  (data.item.typesenior_pending === "JUNIOR" ||
                  data.item.typesenior_pending === "SENIOR")&&(data.item.flag_typesenior===0 ))
                  ||(data.item.typesenior === "begginer" &&  data.item.can_sell === 1 && data.item.flag_settings_management === "DENIED" && data.item.flag_typesenior===0 && data.item.status_filter_crm==='DENIED')
                    ? "-"
                    : data.item.typesenior === "Jr"
                    ? "JUNIOR"
                    : data.item.typesenior.toUpperCase()
                }} -->
                {{
                  data.item.typesenior2 === null ? "-" : data.item.typesenior2
                }}
              </template>
              <template #cell(status_permission)="data">
                <div class="d-flex justify-content-center align-v-center">
                  <b-badge
                    class="text-center w-100"
                    v-if="data.item.status_filter_crm === 'DENIED' || data.item.status_filter_crm === 'APPROVED' || data.item.status_filter_crm === 'PENDING'"
                    :class="
                      data.item.status_filter_crm === 'DENIED'
                        ? 'bg-light-danger'
                        : data.item.status_filter_crm === 'APPROVED'
                        ? 'bg-light-success'
                        : 'bg-light-warning'
                    "
                    pill
                  >
                    {{ data.item.status_filter_crm }} ({{
                      data.item.typesenior_pending
                    }})
                  </b-badge>
                </div>
              </template>
              <template #cell(tracking)="data">
                <div class="text-center">
                  <b-button
                    variant="outline"
                    @click="openModalTracking(data.item)"
                  >
                    <feather-icon
                      class="text-info"
                      v-b-tooltip.hover.right="'Tracking'"
                      icon="ListIcon"
                    >
                    </feather-icon>
                  </b-button>
                </div>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </div>
    </div>
    <modal-tracking
      v-if="modalTrackingState"
      :dataItem="dataItem"
      @hideModal="modalTrackingState = false"
    />
    <modal-category
      :dataClient="dataItem"
      v-if="modalCategoryState"
      @hideModal="closeModal"
      @updatePermission="updatePermission"
      :flagdisabled="flagdisabled"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import fields from "../data/fields.settings.data";
import SettingsService from "../../service/settings.service";
import ModalTracking from "./ModalTracking.vue";
import ModalCategory from "./ModalCategory.vue";
import filters from "../data/filters.settings.data";

export default {
  components: {
    ModalTracking,
    ModalCategory,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "created_at",
      sortDesc: true,
      fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
      dataItem: [],
      modalTrackingState: false,
      modalCategoryState: false,
      flagdisabled: false,
      selected: null,
      options: [
        { value: 1, text: "BEGGINER" },
        { value: 2, text: "JUNIOR" },
        { value: 3, text: "SENIOR" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      // transactions: "LogisticTransactionStore/G_TRANSACTION",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    async refreshCountPending() {
      const { data } = await SettingsService.getCountPermissionPending();
      const payload = {
        routeName: "management-settings",
        tag: data[0].pending,
      };
      await this.countPending(payload);
    },
    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    disabledItemSelect(data, option) {
      let typesenior2 = 0;
      if (data.typesenior2 === "BEGGINER") {
        typesenior2 = 1;
      } else if (data.typesenior2 === "JUNIOR") {
        typesenior2 = 2;
      } else if (data.typesenior2 === "SENIOR") {
        typesenior2 = 3;
      }
      if (typesenior2 === option) {
        return true;
      }
      return false;
    },
    onChangeSelect(e, data) {
      if (e.detail == 0) {
        let typesenior = 0;
        if (data.typesenior === "begginer") {
          typesenior = 1;
        } else if (data.typesenior === "Jr") {
          typesenior = 2;
        } else if (data.typesenior === "Senior") {
          typesenior = 3;
        }
        // if (data.type_pending === typesenior) {
        //   this.showWarningSwal("Permission successfully changed");
        //   return;
        // }

        this.updatePermission(data, 1, data.type_pending);
      }
    },
    disbaledCheckBox(data) {
      return (
        //  (data.item.typesenior === "Jr" || data.item.typesenior === "Senior") &&

        data.item.can_sell === 1 &&
        data.item.flag_settings_management === "PENDING" &&
        data.item.typesenior_pending !== "BEGGINER"
      );
    },
    onChangeSegunCheck(data) {
      if (data.can_sell == 1) {
        this.openModalCategory(data);
        //  this.updatePermission(data, 1, 1);
      } else {
        this.updatePermission(data, 0, null);
      }
    },
    closeModal(data) {
      this.modalCategoryState = false;
      data.can_sell = data.can_sell == 1 ? 0 : 1;
    },
    openModalCategory(data) {
      this.dataItem = data;
      this.modalCategoryState = true;
    },
    async updatePermission(data, opcion, level) {
      // return;

      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          this.flagdisabled = true;
          this.addPreloader();
          const response = await SettingsService.updatePermissionByIdUserModule(
            {
              _user_session_id: this.currentUser.user_id,
              _user_module_id: data.user_module_id,
              _user_id: data.id,
              _opcion: opcion,
              _level: level,
            }
          );
          if (response.status === 200) {
            this.showSuccessSwal("Permission successfully changed");
            if (opcion === 1) {
              this.modalCategoryState = false;
            }
            this.flagdisabled = false;
            this.removePreloader();
            this.refresh();
            this.refreshCountPending();
          }
        } else {
          if (opcion === 0) {
            data.can_sell = data.can_sell == 1 ? 0 : 1;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
        if (opcion === 0) {
          data.can_sell = data.can_sell == 1 ? 0 : 1;
        }
      }
    },
    refresh() {
      this.$refs.refSettingsList.refresh();
    },
    async getAllUsuarios() {
      try {
        const params = {
          texto: this.filterPrincipal.model,
          orderby: 1,
          order: "",
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          status: this.filter[0].model,
        };

        const response = await SettingsService.getAllUsuarios(params);
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        let items = response.data.data;
        items.forEach((item) => {
          if (item.typesenior_pending == "BEGGINER") {
            item.type_pending = 1;
          } else if (item.typesenior_pending == "JUNIOR") {
            item.type_pending = 2;
          } else if (item.typesenior_pending == "SENIOR") {
            item.type_pending = 3;
          } else {
            item.type_pending = item.typesenior_pending;
          }
        });
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    openModalTracking(data) {
      this.dataItem = data;
      this.modalTrackingState = true;
    },
  },
};
</script>

<style></style>
